import { useRoutes } from 'react-router-dom';
import routes from './router';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import LocalizationProvider from '@mui/lab/LocalizationProvider';
import ThemeProvider from './theme/ThemeProvider';
import { CssBaseline } from '@mui/material';
import { useEffect, useState } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { setGlobalState, useGlobalState } from './state/globalstate';
import IdleTimer from './session/idle';
// import { version } from 'react';
import Axios, { API } from './common/api';
import * as crypto from 'crypto';
import React from 'react';
import { Widget, addResponseMessage, toggleMsgLoader } from 'react-chat-widget';
import 'react-chat-widget/lib/styles.css';
import version from '../package.json';
import {
  DeltaIndicator,
  DocumentActions,
  ethnicity_1,
  ethnicity_2,
  Unixdataformatter,
  ActiveIndicator,
  Fullname,
  Religion,
  UpdateOwnerShip
} from './content/dashboard/SearchUserClickHouse/RiderListing/classes';
// import Loading from './loading';

// import logo from './logo.svg';

declare global {
  interface Window {
    analytics: any;
  }
}

const App = () => {
  // // ////console.log(version);
  const isProduction = process.env.NODE_ENV === 'production';
  //console.log('Environment')
  //console.log(isProduction)
  //console.log(version.version)
  const [keyword, setKeyword] = useState<string>('');
  const value = { keyword, setKeyword };
  const content = useRoutes(routes);
  var CryptoTS = require('crypto-ts');

  const [modalmessage] = useGlobalState('modalmessage');

  if (modalmessage) {
    modalmessage;
    setGlobalState('modalmessage', null);
  }

  const [loggedin] = useGlobalState('loggedin');

  //Remove console messages
  if (process.env.NODE_ENV === 'production') {
    //console.log = () => {};
    console.error = () => {};
    console.debug = () => {};
    console.warn = () => {};
    console.info = () => {};
    console.group = () => {};
    console.groupCollapsed = () => {};
    console.trace = () => {};
  } else if (process.env.NODE_ENV === 'development') {
    console.error = () => {};
    console.debug = () => {};
    console.warn = () => {};
    console.info = () => {};
    console.group = () => {};
    console.groupCollapsed = () => {};
    console.trace = () => {};
  }

  useEffect(() => {
    // getCountries
    const getCountries = async () => {
      try {
        await Axios.get(API.getCountries).then((res) => {
          // store the data into our books variable
          const countriesArray = JSON.parse(res.data[0].country);
          console.log('countryArray START ===');
          console.log(countriesArray);
          setGlobalState('countryListing', countriesArray);
          setGlobalState('countriesListing', countriesArray);
        });
      } catch (err) {
        console.log(err);
      }
      try {
        await Axios.get(API.getCountries).then((res) => {
          // store the data into our books variable
          const countriesArray = JSON.parse(res.data[0].country);

          const countryArray = countriesArray;
          countryArray.push('Palestine');
          countryArray.push('Pakistan');
          countryArray.push('India');
          countryArray.push('Bangladesh');
          countryArray.push('Egypt');
          countryArray.push('');
          countryArray.sort(function (a, b) {
            var nameA = a.toLowerCase(),
              nameB = b.toLowerCase();
            if (nameA < nameB)
              //sort string ascending
              return -1;
            if (nameA > nameB) return 1;
            return 0; //default return value (no sorting)
          });
          setGlobalState('countryListingAll', countryArray);
          const allCountriesArray: string[] = countryArray;
          const updatedSelectedCountries: string = allCountriesArray.join(', ');
          setGlobalState('filtersCountries', updatedSelectedCountries);
          console.log('Shery Called App ===');
        });
      } catch (err) {
        console.log(err);
      }
    };
    getCountries();

    // Religion Classifications
    const ReligionClassifications = async () => {
      try {
        await Axios.get(API.ReligionClassifications).then((res) => {
          // store the data into our books variable

          const ReligionArray = res.data;
          console.log(res.data);
          //console.log('countryArray ===')
          //console.log(countryArray)
          setGlobalState('religionListing', ReligionArray);
        });
      } catch (err) {
        console.log(err);
      }
    };
    ReligionClassifications();

    //getAugStatus
    const getAugStatus = async () => {
      try {
        await Axios.get(API.getAugStatus).then((res) => {
          // store the data into our books variable

          const AugStatusArray = res.data;
          console.log(res.data);
          //console.log('countryArray ===')
          //console.log(countryArray)
          setGlobalState('AugStatusArray', AugStatusArray);
        });
      } catch (err) {
        console.log(err);
      }
    };
    getAugStatus();

    //getBallotStatus
    const getBallotStatus = async () => {
      try {
        await Axios.get(API.getBallotStatus).then((res) => {
          // store the data into our books variable

          const BallotStatusArray = res.data;
          console.log(res.data);
          //console.log('countryArray ===')
          //console.log(countryArray)
          setGlobalState('BallotStatusArray', BallotStatusArray);
        });
      } catch (err) {
        console.log(err);
      }
    };
    getBallotStatus();

    // Religion Classifications
    const getOwnership = async () => {
      try {
        await Axios.get(API.getOwnership).then((res) => {
          // store the data into our books variable

          const OwnershipArray = res.data;
          console.log(res.data);
          // console.log('OwnershipArray ===');
          // console.log(OwnershipArray);
          setGlobalState('OwnershipListing', OwnershipArray);
        });
      } catch (err) {
        console.log(err);
      }
    };
    getOwnership();

    // ColumnDefs
    const getColumnDefs = async () => {
      try {
        let VotersListingColumns = [
          {
            headerName: 'Full Name',
            field: 'full_names',
            sortable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            width: 300,
            hide: false
            // cellRenderer: DeltaIndicator
          },
          {
            headerName: 'Voters Id',
            field: 'statevoterid',
            sortable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            // width:: 150,
            hide: true
            // cellRenderer: Fullname,
          },
          {
            headerName: 'City',
            field: 'regcity',
            sortable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            // width:: 170,
            hide: false
            // cellRenderer: DeltaIndicator
          },
          {
            headerName: 'Nov 2024 Status',
            field: 'ballot_status',
            sortable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            // width:: 150,
            hide: false
            // cellRenderer: UpdateOwnerShip
          },
          {
            headerName: 'Reason for Nov 2024',
            field: 'Reason_for_Nov_2024',
            sortable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            // width:: 150,
            hide: false
            // cellRenderer: UpdateOwnerShip
          },
          {
            headerName: 'Aug 2024 Status',
            field: 'Aug_2024_Status',
            sortable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            // width:: 150,
            hide: true
            // cellRenderer: DeltaIndicator
          },
          {
            headerName: 'Religion',
            field: 'llama_names',
            sortable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            // width:: 200,
            hide: false
            // cellRenderer: Religion
          },
          {
            headerName: 'Ethnicity 1',
            field: 'ethnicity_1',
            sortable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            // width:: 200,
            hide: false
            // cellRenderer: ethnicity_1
          },
          {
            headerName: 'Ethnicity 2',
            field: 'ethnicity_2',
            sortable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            // width:: 200,
            hide: false
            // cellRenderer: ethnicity_2
          },
          {
            headerName: 'Last Voted',
            field: 'lastvoted',
            sortable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            // width:: 200,
            hide: false
            // cellRenderer: DeltaIndicator
          },

          {
            headerName: 'Captain',
            field: 'ownershipName',
            sortable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            // width:: 150,
            hide: false
            // cellRenderer: UpdateOwnerShip
          },
          {
            headerName: 'First Name',
            field: 'fname',
            sortable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            // width:: 200,
            hide: false
            // cellRenderer: DeltaIndicator
          },
          {
            headerName: 'Middle Name',
            field: 'mname',
            sortable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            // width:: 200,
            hide: false
            // cellRenderer: DeltaIndicator
          },
          {
            headerName: 'Last Name',
            field: 'lname',
            sortable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            // width:: 200,
            hide: false
            // cellRenderer: DeltaIndicator
          },
          {
            headerName: 'Address',
            field: 'fulladdress',
            sortable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            // width:: 220,
            hide: false
            // cellRenderer: DeltaIndicator
          },

          {
            headerName: 'Zip Code',
            field: 'regzipcode',
            sortable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            // width:: 150,
            hide: false
            // cellRenderer: DeltaIndicator
          },
          {
            headerName: 'Gender',
            field: 'gender',
            sortable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            // width:: 150,
            hide: false
            // cellRenderer: DeltaIndicator
          },
          {
            headerName: 'Birth Year',
            field: 'birthyear',
            sortable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            // width:: 150,
            hide: false
            // cellRenderer: DeltaIndicator
          },
          {
            headerName: 'Congressional District',
            field: 'congressionaldistrict',
            sortable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            // width:: 150,
            hide: false
            // cellRenderer: DeltaIndicator
          },
          {
            headerName: 'Legislative District',
            field: 'legislativedistrict',
            sortable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            // width:: 150,
            hide: false
            // cellRenderer: DeltaIndicator
          },

          {
            headerName: 'Nov 2024 Challenge Reason',
            field: 'challenge_reason',
            sortable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            // width:: 150,
            hide: false
            // cellRenderer: UpdateOwnerShip
          },
          {
            headerName: 'Nov 2024 Received Date',
            field: 'received_date',
            sortable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
            // width:: 150,
            hide: false
            // cellRenderer: UpdateOwnerShip
          }
        ];

        const getColumns = localStorage.getItem('VoterListingColumnDefs');
        console.log('VotersListingColumns ===');
        console.log(VotersListingColumns);
        if (!getColumns) {
          let VotersListingColumnsString = JSON.stringify(VotersListingColumns);
          localStorage.setItem(
            'VoterListingColumnDefs',
            VotersListingColumnsString
          );
        }
      } catch (err) {
        console.log(err);
      }
    };
    getColumnDefs();
  }, []);

  // useEffect(() => {
  //   addResponseMessage('Welcome to this AMAC Voters Q chat!');
  // }, []);

  // const handleNewUserMessage = async (newMessage) => {
  //   let chatResponse = '';
  //   toggleMsgLoader();
  //   ////console.log(`New message incoming! ${newMessage}`);
  //   const params = { question: encodeURIComponent(newMessage) };
  //   try {
  //     await Axios.get(API.mlrcBedrock, {
  //       params
  //     }).then((res) => {
  //       // store the data into our books variable
  //       ////console.log(res.data.response);
  //       chatResponse = res.data.response;
  //       // let chatResponse = res.data;
  //     });
  //   } catch (err) {
  //     // setGlobalState('ErrorFoundGlobal', false);
  //     // ////console.log(err);
  //   }

  //   addResponseMessage(chatResponse);
  //   toggleMsgLoader();
  //   // Now send the message throught the backend API
  // };

  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <CssBaseline />
        {content}
        <IdleTimer />
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        {/* <div className="App">
          <Widget
            handleNewUserMessage={handleNewUserMessage}
            // profileAvatar={logo}
            title="AMAC Voters Q"
            // subtitle="And my cool subtitle"
          />
        </div> */}
      </LocalizationProvider>
    </ThemeProvider>
  );
};
export default App;
